import(/* webpackMode: "eager", webpackExports: ["LayoutShell"] */ "/vercel/path0/apps/web/src/app/_/components/layout-shell.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JobBoardFilter"] */ "/vercel/path0/apps/web/src/app/(unauthenticated)/job-board/_/components/filter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/apps/web/src/app/(unauthenticated)/job-board/_/components/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationProvider"] */ "/vercel/path0/apps/web/src/shared/providers/hydration-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["JobBoardFilterProvider"] */ "/vercel/path0/apps/web/src/shared/providers/job-board-filter-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.59.0_react@18.3.1/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.15_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/blocks/lib/components/footer/index.tsx");
