import {Collapsible, TextButton} from '@job-ish/ui/components';
import {IconChevronDown, IconChevronUp} from '@tabler/icons-react';

import type {PropsWithChildren} from 'react';

export type JobBoardFilterWrapperProps = PropsWithChildren;

export const JobBoardFilterWrapper = ({children}: JobBoardFilterWrapperProps) => (
	<>
		<div className="hidden sm:block">{children}</div>
		<Collapsible className="sm:hidden" defaultOpen>
			<Collapsible.Content>{children}</Collapsible.Content>
			<Collapsible.Trigger
				className="mx-auto mb-2"
				closeTrigger={
					<TextButton iconRight={IconChevronUp} size="sm">
						Hide Search
					</TextButton>
				}
				openTrigger={
					<TextButton iconRight={IconChevronDown} size="sm">
						Show Search
					</TextButton>
				}
			/>
		</Collapsible>
	</>
);
