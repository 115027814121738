import {useCallback, useRef, useState} from 'react';
import {PREFFIX_TO_FILTER_KEY, PREFIX_TO_FILTER_LABEL} from '~/shared/constants/job-board';
import {Badge, Button, FormControl, SearchField, Select} from '@job-ish/ui/components';
import {IconFilterSearch} from '@tabler/icons-react';

import type {KeyboardEvent, ReactNode} from 'react';

type JobBoardFilterSearchField = {
	value?: string[];
	onChange: (value: string[]) => void;
	input: string;
	onInputChange: (searchTerm: string) => void;
	prefix: keyof typeof PREFFIX_TO_FILTER_KEY;
	onPrefixChange: (value: keyof typeof PREFFIX_TO_FILTER_KEY) => void;
	onFormSubmit: () => void;
	loading?: boolean;
	emptyFilterFallback?: ReactNode;
};

export const JobBoardFilterSearchField = ({
	value = [],
	onChange,
	prefix,
	onPrefixChange,
	input,
	onInputChange,
	onFormSubmit,
	loading,
	emptyFilterFallback,
}: JobBoardFilterSearchField) => {
	const [open, setOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleAddSearchTerm = useCallback(
		(searchTerm: string) => {
			const filteredSearchTerm = searchTerm.replaceAll('"', '').trim();
			if (filteredSearchTerm === '') return;
			if (!value.includes(filteredSearchTerm)) {
				onChange([...value, `${PREFFIX_TO_FILTER_KEY[prefix]}${filteredSearchTerm}`]);
			}
			onInputChange('');
		},
		[onChange, onInputChange, prefix, value],
	);

	const handleDismiss = useCallback(
		(searchTerm: string) => {
			onChange(value.filter(value => value !== searchTerm));
		},
		[onChange, value],
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === ',' || event.key === '|') {
				event.preventDefault();
				handleAddSearchTerm(input);
			}

			if (event.key === 'Enter') {
				setSubmitting(true);
				event.preventDefault();
				onFormSubmit();
			}

			if (event.key === 'Escape') {
				onInputChange('');
			}
		},
		[handleAddSearchTerm, input, onFormSubmit, onInputChange],
	);

	const focusInput = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const handleInputChange = useCallback(
		(searchTerm: string) => {
			onInputChange(searchTerm);
		},
		[onInputChange],
	);

	const handleOnCloseAutoFocus = useCallback(
		(event: Event) => {
			{
				event.preventDefault();
				if (/AppleWebKit/.test(navigator.userAgent) && /iPhone|iPod|iPad/.test(navigator.userAgent)) {
					return;
				}
				focusInput();
			}
		},
		[focusInput],
	);

	return (
		<div className="flex w-full flex-col gap-1">
			<div className="flex flex-wrap items-center gap-1">
				<Select
					onOpenChange={open => (submitting ? setSubmitting(false) : setOpen(open))}
					onValueChange={value => onPrefixChange(value as keyof typeof PREFFIX_TO_FILTER_KEY)}
					open={open}
					value={prefix}
				>
					<Select.Trigger className="w-full sm:w-28" size="sm">
						{PREFIX_TO_FILTER_LABEL[prefix]}
					</Select.Trigger>
					<Select.Content
						align="start"
						className="ml-0.5 w-72 sm:w-96"
						onCloseAutoFocus={handleOnCloseAutoFocus}
					>
						<Select.Group className="p-0">
							<Select.Label>Search Options</Select.Label>
							<Select.Item className="h-fit" value="title">
								<div className="flex flex-col whitespace-normal">
									<div>Job Title</div>
									<div className="text-xs text-mauve11">Search for text in the job title</div>
								</div>
							</Select.Item>
							<Select.Separator />
							<Select.Item className="h-fit" value="keyword">
								<div className="flex flex-col whitespace-normal">
									<div>Keyword</div>
									<div className="text-xs text-mauve11">
										Search for a keyword or phrase in the job description
									</div>
								</div>
							</Select.Item>
							<Select.Separator />
							<Select.Item className="h-fit" value="exclude">
								<div className="flex flex-col whitespace-normal">
									<div>Exclude</div>
									<div className="text-xs text-mauve11">
										Exclude results that contain the specified keyword or phrase in the job title or
										description
									</div>
								</div>
							</Select.Item>
						</Select.Group>
					</Select.Content>
				</Select>
				<FormControl className="shrink grow">
					<SearchField
						aria-label="Search Jobs"
						className="w-full min-w-0 [&>div]:h-3 [&_input]:w-0"
						clearable
						label="Search Jobs"
						onChange={handleInputChange}
						onKeyDown={handleKeyDown}
						placeholder="Search a keyword or phrase..."
						ref={inputRef}
						size="sm"
						suffix={
							input && (
								<Button onPress={() => handleAddSearchTerm(input)} size="xs">
									Add
								</Button>
							)
						}
						value={input}
					/>
				</FormControl>
				<Button
					className="w-full sm:w-fit"
					color="primary"
					iconRight={IconFilterSearch}
					loading={loading}
					onPress={() => setOpen(false)}
					size="sm"
					type="submit"
				>
					<span className="hidden sm:block">Search</span>
					<span className="sm:hidden">Search Jobs</span>
				</Button>
			</div>
			<div className="flex h-8 gap-1 overflow-x-auto p-0.5">
				{value.length > 0
					? value.map((searchTerm, index) => (
							<Badge
								className="h-fit max-w-full"
								color={
									searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.exclude)
										? 'danger'
										: searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.title)
											? 'info'
											: 'success'
								}
								dismissable
								key={`${searchTerm}-${index}`}
								onDismiss={() => handleDismiss(searchTerm)}
								size="sm"
							>
								<span className="truncate">
									{searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.exclude)
										? searchTerm.slice(PREFFIX_TO_FILTER_KEY.exclude.length)
										: searchTerm.startsWith(PREFFIX_TO_FILTER_KEY.title)
											? searchTerm.slice(PREFFIX_TO_FILTER_KEY.title.length)
											: searchTerm.slice(PREFFIX_TO_FILTER_KEY.keyword.length)}
								</span>
							</Badge>
						))
					: emptyFilterFallback}
			</div>
		</div>
	);
};
