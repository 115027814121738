import {forwardRef, useEffect, useState} from 'react';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ReactNode} from 'react';
import type {CloseButtonProps} from '../close-button';

export type CollapsibleProps = CollapsiblePrimitive.CollapsibleProps;

export type CollapsibleContentProps = CollapsiblePrimitive.CollapsibleContentProps;
const CollapsibleContent = forwardRef<HTMLDivElement, CollapsibleContentProps>(
	({className, ...props}, forwardedRef) => {
		const [mounted, setMounted] = useState(false);

		useEffect(() => {
			setMounted(true);
		}, []);

		return (
			<CollapsiblePrimitive.Content
				{...props}
				className={twMerge(
					'overflow-hidden',
					mounted && 'data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down',
					className,
				)}
				ref={forwardedRef}
			/>
		);
	},
);

export type CollapsibleTriggerProps = Omit<
	CollapsiblePrimitive.CollapsibleTriggerProps,
	'asChild' | 'intent'
> &
	CloseButtonProps & {
		closeTrigger: ReactNode;
		openTrigger: ReactNode;
	};

const CollapsibleTrigger = forwardRef<HTMLButtonElement, CollapsibleTriggerProps>(
	({closeTrigger, openTrigger, className, ...props}, forwardedRef) => (
		<>
			<CollapsiblePrimitive.Trigger
				{...props}
				asChild
				className={twMerge('group-data-[state=open]:hidden', className)}
				ref={forwardedRef}
			>
				{openTrigger}
			</CollapsiblePrimitive.Trigger>
			<CollapsiblePrimitive.Trigger
				{...props}
				asChild
				className={twMerge('group-data-[state=closed]:hidden', className)}
				ref={forwardedRef}
			>
				{closeTrigger}
			</CollapsiblePrimitive.Trigger>
		</>
	),
);

const Collapsible = assign(
	forwardRef<HTMLDivElement, CollapsibleProps>(({className, ...props}, forwardedRef) => (
		<CollapsiblePrimitive.Root {...props} className={twMerge('group', className)} ref={forwardedRef} />
	)),
	{Content: CollapsibleContent, Trigger: CollapsibleTrigger},
);

Collapsible.displayName = 'Collapsible';
Collapsible.Content.displayName = 'Collapsible.Content';
Collapsible.Trigger.displayName = 'Collapsible.Trigger';

export default Collapsible;
